const DEFAULT_REDIRECT = '/'

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */

export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return defaultRedirect
  }

  return to
}

export function validateEmail(email: unknown): email is string {
  return (
    typeof email === 'string' &&
    email.length > 3 &&
    email.includes('@') &&
    email.includes('.')
  )
}

export function isFormProcessing(state: 'idle' | 'submitting' | 'loading') {
  return state === 'submitting' || state === 'loading'
}
