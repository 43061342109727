import { LoaderFunctionArgs, json, redirect } from '@vercel/remix'
import transitivWhite from '~/images/TransitivLogoWhite.svg'
import { useLoaderData, useSearchParams } from '@remix-run/react'
import { useEffect, useRef, useState } from 'react'
import { getSupabaseSession } from '~/models/session.server'
import { Button } from '~/components/ui/button'
import { useToast } from '~/components/ui/use-toast'
import {
  checkAuthErrorCookie,
  createAuthErrorCookie,
} from '~/models/cookies.server'
import OTPSignInForm from '~/components/otp-sign-in-form'
import { useBaseURL } from '@/utils/url'
import { useRootContext } from '~/root'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { data: sessionData } = await getSupabaseSession(request)
  if (sessionData?.session) {
    throw redirect('/app')
  }

  const data = {
    authError: await checkAuthErrorCookie(request),
  }
  return json(data, {
    headers: {
      'Set-Cookie': await createAuthErrorCookie.serialize({}),
    },
  })
}

export default function Login() {
  const { authError } = useLoaderData<typeof loader>()
  const { supabase } = useRootContext()
  const { toast } = useToast()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectUrl') ?? '/'
  const emailRef = useRef<HTMLInputElement>(null)
  const rememberRef = useRef<HTMLInputElement>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const baseURL = useBaseURL()
  useEffect(() => {
    if (emailError) {
      emailRef.current?.focus()
    }
  }, [emailError])

  useEffect(() => {
    if (authError) {
      setTimeout(() => {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: authError,
        })
      }, 500)
    }
  }, [])

  useEffect(() => {
    const remember = localStorage.getItem('remember')
    if (remember === 'true' && rememberRef.current) {
      rememberRef.current.checked = true
    }
    const email = localStorage.getItem('email')
    if (email && emailRef.current) {
      emailRef.current.value = email
    }
  }, [])

  const handleGoogleAuth = async () => {
    let redirectURL = baseURL + '/auth/callback'
    if (redirectTo) {
      redirectURL += '?redirect=' + encodeURIComponent(redirectTo)
    }
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
        redirectTo: redirectURL,
      },
    })

    if (error) {
      setEmailError(error.message)
    }
  }

  return (
    <div className='min-h-full bg-gradient-to-tr from-transitiv-blue to-transitiv-blue-light py-10'>
      <div className='mx-auto flex w-full max-w-md flex-col'>
        <img src={transitivWhite} className='mx-auto w-3/4' />
        <div className='mt-10 rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='p-4 sm:p-7'>
            <div className='mt-5'>
              <div className='mb-3 text-center text-3xl font-semibold'>
                Sign In
              </div>
              <div className='mb-3 flex justify-center gap-1 text-xs'>
                <div>Don't have an account setup yet?</div>
                <a
                  className='cursor-pointer font-semibold text-transitiv-purple'
                  href='mailto:support@transitiv.io'
                >
                  Contact support
                </a>
              </div>
              <Button
                variant='outline'
                type='button'
                onClick={() => {
                  handleGoogleAuth()
                }}
                className='my-4 inline-flex w-full items-center justify-center gap-2'
              >
                {/* Google SVG IMG */}
                <svg
                  className='h-auto w-4'
                  width='46'
                  height='47'
                  viewBox='0 0 46 47'
                  fill='none'
                >
                  <path
                    d='M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z'
                    fill='#4285F4'
                  />
                  <path
                    d='M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z'
                    fill='#34A853'
                  />
                  <path
                    d='M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z'
                    fill='#FBBC05'
                  />
                  <path
                    d='M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z'
                    fill='#EB4335'
                  />
                </svg>
                Sign in with Google
              </Button>
              <div className='relative my-4'>
                <div className='absolute inset-0 flex items-center'>
                  <span className='w-full border-t' />
                </div>
                <div className='relative flex justify-center text-xs uppercase'>
                  <span className='bg-background px-2 text-muted-foreground'>
                    or
                  </span>
                </div>
              </div>
              <OTPSignInForm redirectTo={redirectTo} supabase={supabase} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
