import { FormEvent, useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import { Button } from '~/components/ui/button'
import { Input } from '~/components/ui/input'
import { validateEmail } from 'utils/form'
import { toast } from '~/components/ui/use-toast'
import { SupabaseClient } from '@supabase/supabase-js'
import SubmissionButton from '~/components/ui/submissionButton'
import { Label } from '~/components/ui/label'
import { Form, useNavigate, useSearchParams } from '@remix-run/react'

export default function OTPSignInForm({
  redirectTo,
  supabase,
}: {
  redirectTo: string
  supabase: SupabaseClient<any, 'public', any>
}) {
  const [verifyOTPView, setVerifyOTPView] = useState(false)
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [formIsProcessing, setFormIsProcessing] = useState(false)
  const [resendOTP, setResendOTP] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('inviteEmail') && searchParams.get('code')) {
      setEmail(searchParams.get('inviteEmail') as string)
      setOtp(searchParams.get('code') as string)
      setVerifyOTPView(true)
    }
  }, [searchParams])

  useEffect(() => {
    setResendOTP(false)
  }, [otp])

  const handleOTPInputChange = (otp: string) => {
    // remove non-digits
    const digits = otp.replace(/\D/g, '')
    setOtp(digits)
  }

  const handleSubmitMagicLink = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormIsProcessing(true)
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
      },
    })
    if (error) {
      toast({
        variant: 'destructive',
        title: 'Error - Account does not exist for this email',
        description:
          error.message === 'Signups not allowed for this instance'
            ? 'Contact support if you believe this is a mistake'
            : error.message,
      })
    } else {
      toast({
        variant: 'default',
        title: 'OTP Email Sent!',
        description: 'You have been sent an email with your OTP Code',
      })
      setVerifyOTPView(true)
      if (resendOTP) {
        setResendOTP(false)
        setOtp('')
      }
    }
    setFormIsProcessing(false)
  }

  const handleVerifyOTP = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormIsProcessing(true)
    const { error } = await supabase.auth.verifyOtp({
      email: email.toLowerCase(),
      token: otp,
      type: 'email',
    })
    if (error) {
      let title = 'Uh Oh! Invalid OTP Code'
      let description = error.message
      if (error.message === 'User not found') {
        title = 'Uh Oh! Account does not exist for this email'
        description = 'Please double check the email you used'
      }
      toast({
        variant: 'destructive',
        title: title,
        description: description,
      })
      setResendOTP(true)
      setFormIsProcessing(false)
    } else {
      let redirectURL = '/'
      if (redirectTo) {
        redirectURL = redirectTo
      }
      navigate(redirectURL)
    }
  }

  return (
    <>
      {verifyOTPView ? (
        <div className='mx-auto'>
          <Form
            method='post'
            onSubmit={resendOTP ? handleSubmitMagicLink : handleVerifyOTP}
            className='otpContainer flex flex-col'
          >
            <div className='mb-8 mt-4 text-center text-lg'>
              Verify your OTP Code
            </div>
            <OTPInput
              shouldAutoFocus
              value={otp}
              onChange={handleOTPInputChange}
              numInputs={6}
              inputType='number'
              renderSeparator={<div className='w-4'></div>}
              containerStyle={{ justifyContent: 'center' }}
              inputStyle={{ width: '2.5rem' }}
              renderInput={(props) => (
                <Input {...props} className='h-10 w-10' />
              )}
            />
            <div className='my-8 flex text-sm text-slate-500'>
              <ul className='mx-auto w-3/4 list-disc'>
                <li>
                  Copy and paste your OTP Code you received from your email.
                </li>
                <li>
                  Be sure to use the <b>most recent</b> email you received.
                </li>
                <li>All prior emails will contain expired OTP codes.</li>
                <li>
                  OTP codes are <b>only valid for 24 hours</b>, or until a new
                  OTP code is requested.
                </li>
              </ul>
            </div>
            <div className='mb-8 mt-4'>
              <Label htmlFor='verifyEmail' className='mb-2 block text-sm '>
                Email Used to Receive OTP Code
              </Label>
              <Input
                name='verifyEmail'
                type='email'
                autoComplete='email'
                value={email}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                required
                disabled={formIsProcessing}
              />
            </div>
            <div className='grid w-full grid-cols-3 gap-4'>
              <Button
                type='button'
                variant='outline'
                onClick={() => setVerifyOTPView(false)}
                className='col-span-1'
              >
                Back
              </Button>
              <SubmissionButton
                variant={resendOTP ? 'secondary' : 'default'}
                isSubmitting={formIsProcessing}
                disabled={
                  otp.length !== 6 || !validateEmail(email) || formIsProcessing
                }
                className='col-span-2'
              >
                {resendOTP ? 'Get New OTP Code' : 'Verify'}
              </SubmissionButton>
            </div>
          </Form>
        </div>
      ) : (
        <Form method='post' onSubmit={handleSubmitMagicLink}>
          <div className='grid gap-y-4'>
            <div>
              <Label htmlFor='email' className='mb-2 block text-sm '>
                Franchise Network Email
              </Label>
              <div className='relative'>
                <Input
                  autoFocus={true}
                  name='email'
                  type='email'
                  autoComplete='email'
                  value={email}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  aria-describedby='email-error'
                  required
                  disabled={formIsProcessing}
                />
                <div className='my-2 text-xs text-slate-500'>
                  Receive a One-Time-Password (OTP) Code to sign in
                </div>
              </div>
            </div>
            <SubmissionButton
              isSubmitting={formIsProcessing}
              type='submit'
              disabled={formIsProcessing || !validateEmail(email)}
            >
              Get OTP Code
            </SubmissionButton>
            <div
              className='mx-auto my-4 cursor-pointer text-center text-sm text-transitiv-blue underline'
              onClick={() => setVerifyOTPView(true)}
            >
              Already have an OTP Code?
            </div>
          </div>
          <input type='hidden' name='redirectTo' value={redirectTo} />
        </Form>
      )}
    </>
  )
}
